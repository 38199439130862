import { Injectable, signal } from "@angular/core";
import { PolicyPeriod } from "../models/policy";
import { PartialDeep } from "type-fest";
import { merge } from "lodash";

@Injectable({
  providedIn: "root",
})
export class PolicyPeriodStoreService {
  #periods = signal<PolicyPeriod[]>([]);
  periods = this.#periods.asReadonly();

  record(period: PolicyPeriod) {
    this.#periods.update((current) => {
      // remove the associated period if it is already stored
      const filtered = current.filter(
        (p) => p.policyTransactionId !== period.policyTransactionId,
      );
      // add the provided period to the store
      return [...filtered, period];
    });
  }

  update(policyTransactionId: string, patch: PartialDeep<PolicyPeriod>) {
    const period = this.#periods().find(
      (p) => p.policyTransactionId === policyTransactionId,
    );

    if (!period) {
      // bail out if no policy period is found
      return undefined;
    }

    // merge the provided update patch
    const updated = merge({}, period, patch);

    // update the stored policy period
    this.#periods.update((current) => {
      const filtered = current.filter(
        (p) => p.policyTransactionId !== policyTransactionId,
      );
      return [...filtered, updated];
    });
  }
}
